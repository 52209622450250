import React from 'react'
import AltPageLayout from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import Text from 'Components/texts/Text'
import { withNewLines } from 'Utils/api'
import {
  ArticlesData,
  getSortedArticles,
  useMaybeChangeLanguageOnInit,
} from 'Utils/common'
import type { PageDataType } from 'Typings/api'
import {
  CategoriesFilter,
  CategoriesFilterParams,
} from 'Components/filters/categories/CategoriesFilter'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { ArticlesList } from 'Components/Article/ArticlesList'
import { size } from 'lodash'
import Row from 'Components/Row/Row'
import { StickyAside } from 'Components/StickyAside/StickyAside'
import { ArticlesRecommendations } from 'Components/Article/ArticlesRecommendations'
import { fadeUp } from 'Utils/animations'
import { FormSection } from 'Components/sections/FormSection'
import styles from './ArticlesPage.module.scss'

export type ArticlesPageProps = PageDataType & {
  articlesData: ArticlesData
}

const ArticlesPage = (props: ArticlesPageProps) => {
  const { data, path, articlesData } = props

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('ArticlesPage', data)

  if (!data || !articlesData || !isInit) {
    return null
  }

  const renderList = ({ selectedItems }: CategoriesFilterParams) => {
    const articles = getSortedArticles({ edges: selectedItems } as ArticlesData)

    if (size(articles) === 0) {
      return null
    }

    return <ArticlesList articles={articles} />
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        overflow={false}
        seo={data.seo}
        path={path}
        hasHeaderIndent={false}
        headerClassName={styles.header}
      >
        <BaseContainer vertical="none">
          <Row vertical="top" className={styles.row}>
            <div className={styles.wrapper}>
              {Boolean(data.heading) && (
                <Text
                  Component="h1"
                  className={styles.title}
                  variant="heading"
                  {...fadeUp({ index: 1 })}
                >
                  {withNewLines(data.heading)}
                </Text>
              )}
              {Boolean(data.sub_heading) && (
                <Text className={styles.subTitle} {...fadeUp({ index: 2 })}>
                  {withNewLines(data.sub_heading)}
                </Text>
              )}
              <CategoriesFilter
                data={articlesData}
                entityName="node"
                entitiesName="edges"
                categoryName="main_tag"
                renderList={renderList}
                className={styles.filters}
                withContainer={false}
                {...fadeUp({ index: 3 })}
              />
            </div>
            <StickyAside className={styles.aside} {...fadeUp({ index: 2 })}>
              <ArticlesRecommendations
                data={articlesData}
                className={styles.recommendations}
              />
            </StickyAside>
          </Row>
        </BaseContainer>
        <FormSection />
      </AltPageLayout>
    </>
  )
}

export default ArticlesPage
